











import { NotificationBoxType } from '@/Model/notificationBox/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ToggleSwitch extends Vue {
  @Prop({ type: String, default: NotificationBoxType.Info }) type!: NotificationBoxType;

  get typeStyleClass(): string {
    return `notification-box-${this.type.toLocaleLowerCase()}`;
  }
}
